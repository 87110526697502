import { cn } from '@/lib/utils';
import { File } from 'lucide-react';
import { CSSProperties } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import { Asset } from '@/types/asset';
import { DraggedImage } from './dragged-image';
import { Badge } from './ui/badge';

export type CustomDragLayerProps = {
  items: Array<Asset>;
  selectedAssetIds: Array<{ id: string; name: string }>;
  containerRef: React.RefObject<HTMLDivElement>;
};

export const CustomDragLayer = ({ items, selectedAssetIds, containerRef }: CustomDragLayerProps) => {
  const { item, isDragging, clientOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    clientOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }

  const selectedAssets = items?.filter(
    (asset) => selectedAssetIds.some((id) => id.id === asset.id) && asset.id !== item.id,
  );
  const stackedAssets = selectedAssets.slice(0, 5);

  const containerRect = containerRef?.current?.getBoundingClientRect();

  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(clientOffset, item.width, item.height, containerRect?.left ?? 0, containerRect?.top ?? 0)}
      >
        {item.thumbnailUrl ? (
          <>
            {/* We always want the dragged image on top */}
            <DraggedImage
              key={`$dragged-image-${item.id}`}
              index={0}
              name={item.name}
              width={item.width}
              height={item.height}
              stackedAssetsLength={stackedAssets.length + 1}
              draggedThumbnailUrl={item.thumbnailUrl}
              classname={cn(
                'aspect-square h-auto w-full rounded-md bg-neutral-100 object-contain transition-all hover:cursor-pointer dark:bg-neutral-900',
                { 'opacity-50': item.trashed },
              )}
            />
            {stackedAssets.map((asset: Asset, index: number) => (
              <DraggedImage
                key={`$dragged-image-${asset.id}`}
                index={index + 1}
                name={asset.name}
                width={item.width}
                height={item.height}
                stackedAssetsLength={stackedAssets.length + 1}
                asset={asset}
                classname={cn(
                  'aspect-square h-auto w-full rounded-md bg-neutral-100 object-contain transition-all hover:cursor-pointer dark:bg-neutral-900',
                  { 'opacity-50': item.trashed },
                )}
              />
            ))}
          </>
        ) : (
          <div className="flex aspect-square h-auto w-full items-center justify-center rounded-md bg-neutral-100 hover:cursor-pointer dark:bg-neutral-900">
            <File className="size-14 stroke-neutral-500 dark:stroke-neutral-700" strokeWidth={1} />
          </div>
        )}
        {isDragging && (
          <div className="absolute bottom-[-0.75rem] right-[-0.75rem] z-[10]">
            <Badge variant="destructive"> {selectedAssetIds?.length !== 0 ? selectedAssetIds.length : 1}</Badge>
          </div>
        )}
      </div>
    </div>
  );
};

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

function getItemStyles(clientOffset: XYCoord | null, width: number, height: number, left: number, top: number) {
  if (!clientOffset) {
    return {
      display: 'none',
    };
  }

  const x = clientOffset.x - left - width / 2;
  const y = clientOffset.y - top - height / 2;

  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
    width,
    height,
  };
}
