import { useAlbumAssets } from '@/hooks/data/albums/useAlbumAssets';

export const ReorderAssetsCommand = (assetIds: Array<string>, albumId: string | Array<string> | undefined) => {
  const { reorderAssets } = useAlbumAssets();
  let position: number;
  let oldPosition: number;

  const apply = (): void => {
    if (albumId !== undefined) {
      void reorderAssets({ albumId: String(albumId), assetIds, position });
    }
  };

  const update = (params: { position: number; oldPosition: number }) => {
    position = params.position;
    oldPosition = params.oldPosition;
  };

  const undo = (): void => {
    if (albumId !== undefined) {
      void reorderAssets({ albumId: String(albumId), assetIds, position: oldPosition });
    }
  };

  const redo = (): void => {
    void apply();
  };

  return {
    apply,
    update,
    undo,
    redo,
  };
};
